<template>
  <v-app>
    <v-app-bar class="teal darken-1" v-if="showAppbar && isLogged()" app dark>
    <Navbar />
    </v-app-bar>
    <LiveSessionList />
    <Notification />
  </v-app>
</template>

<script>
import Base from "@/mixins/base.js";
import Component, { mixins } from "vue-class-component";
import { Utility } from "@/lib/Utility";
import { localStorageService } from "@/services/localStorageService.js";
import Logo from "@/components/logo";
import Notification from "@/components/dom/notification";
import Navbar from "@/components/navbar/Navbar";
import LiveSessionList from "@/components/liveSessions/LiveSessionList";

@Component({
  name: "Home",
  components:{
    Logo,
    Notification,
    Navbar,
    LiveSessionList
  }

})
export default class Home extends mixins(Base) {
 get showAppbar() {
    return this.$route.meta?.showAppbar;
  }

  recomputeFlags() {
    this.recomputeFlagId = Utility.uid("ab");
  }

  isLogged() {
    return !!localStorageService.getLoggedUser();
  }

  logout() {
    localStorageService.removeLoggedUser();
    this.$router.push("/login");
  }

  selectLanguage(language, $event) {
    $event.preventDefault();
    this.setCurrLang(language);
  }

  isActive(language) {
    const lang = this.currLang();
    return language.id === lang?.id;
  }

}
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="livesessions-page" v-if="isPageReady">
    <div class="livesessions-page__content">
      <div class="livesessions-page__filters">
        <v-sheet tile height="190" class="d-flex pt-4">
          <v-container>
            <v-row class="mb-n6">
              <v-col cols="4" v-if="adminView">
                <v-select
                  v-model="speakerToImpersonate"
                  :items="speakers"
                  item-text="fullName"
                  item-value="id"
                  hide-details
                  clearable
                  label="Speaker to impersonate"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  class="text-filter"
                  v-model="text_filter"
                  label="Search"
                  prepend-icon="mdi-magnify"
                  clear-icon="mdi-close-circle"
                  clearable
                  @click:clear="resetFilter"
                ></v-text-field>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-radio-group row v-model="type_filter">
                  <template v-slot:label>
                    <div>Filter for Live Session's Type</div>
                  </template>
                  <v-radio value="ALL">
                    <template v-slot:label>
                      <div>
                        <strong>All</strong>
                      </div>
                    </template>
                  </v-radio>
                  <v-radio
                    v-for="item in legenda"
                    :value="item.type"
                    :key="item.type"
                  >
                    <template v-slot:label>
                      <div>
                        <strong :class="`${item.color}--text`">{{
                          item.type
                        }}</strong>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="6">
                <v-row no-gutters>
                  <v-col cols="2">
                    <v-checkbox
                      v-model="status_filter"
                      value="ALL"
                      v-on:click="StatusFilterCheck(true)"
                    >
                      <template v-slot:label>
                        <div>
                          <strong>All</strong>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="status_filter"
                      value="ComingSoon"
                      v-on:click="StatusFilterCheck(false)"
                    >
                      <template v-slot:label>
                        <div>
                          <strong class="pink--text">Coming Soon</strong>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="status_filter"
                      value="Started"
                      v-on:click="StatusFilterCheck(false)"
                    >
                      <template v-slot:label>
                        <div>
                          <strong class="light-green--text">Started</strong>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                  <v-col cols="3">
                    <v-checkbox
                      v-model="status_filter"
                      value="Ended"
                      v-on:click="StatusFilterCheck(false)"
                    >
                      <template v-slot:label>
                        <div>
                          <strong class="deep-orange--text">Ended</strong>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </div>
      <div>
        <v-sheet tile height="54" class="d-flex">
          <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-select
            v-model="type"
            :items="types"
            dense
            outlined
            hide-details
            class="ma-2"
            label="Type"
          ></v-select>
          <v-select
            v-model="weekday"
            :items="weekdays"
            dense
            outlined
            hide-details
            label="Weekdays"
            class="ma-2"
            :style="isDailyCalendar ? `opacity:0` : ``"
          ></v-select>
          <v-spacer></v-spacer>
          <v-btn icon class="ma-2" @click="$refs.calendar.next()">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="value"
            :weekdays="weekday"
            :type="type"
            :events="appointments_filtered"
            event-overlap-mode="column"
            :event-overlap-threshold="30"
            :event-color="getEventColor"
            @change="getEvents"
            @click:event="showEvent"
            @click:more="viewDay"
            @click:date="viewDay"
          >
            <template v-slot:event="{ event }">
              <v-icon small dark v-if="event.status == 'Started'"
                >mdi-play-circle-outline</v-icon
              >
              <span
                :class="event.status == 'Started' ? 'event_on_air' : 'event'"
              >
                <strong class="ml-1">
                  {{
                    event.start
                      .toLocaleTimeString()
                      .match(/\d{2}:\d{2}|[AMP]+/g)
                      .join(" ")
                  }}
                </strong>
                {{ event.name }}
              </span>
            </template>
          </v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="selectedEvent.color" dark>
                <v-icon class="mr-3">mdi-information-outline</v-icon>
                <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="selectedOpen = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <span
                  v-html="getFormattedHTMLDescription(selectedEvent)"
                ></span>
                <div
                  class="open-meeting-options"
                  v-if="
                    selectedEvent &&
                    selectedEvent.status &&
                    selectedEvent.status.toLowerCase() != 'ended'
                  "
                >
                  <v-divider></v-divider>
                  <v-switch
                    v-if="selectedEvent.type == 'Reception'"
                    :input-value="isAvailableInVirtualPlace"
                    label="Available in Virtual Place"
                    :disabled="switchDisable"
                    inset
                    @change="
                      swapAvailability(
                        selectedEvent.id,
                        isAvailableInVirtualPlace
                      )
                    "
                  ></v-switch>
                  <v-divider></v-divider>
                  <div v-if="isAvailableInVirtualPlace">
                    <div class="caption mt-4">
                      Click on the language you want to open the meeting!
                    </div>
                    <v-btn
                      class="mt-2"
                      v-for="item in selectedEvent.localizations"
                      :value="item.type"
                      :key="item.id"
                      icon
                      @click="openLocalizedLiveSession(item)"
                    >
                      <country-flag
                        :country="getLocalizationCode(item)"
                        size="normal"
                        class="ma-auto"
                      />
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-sheet>
      </div>
    </div>
    <Loading v-if="!isPageReady" />
  </div>
</template>

<script>
import Base from "@/mixins/base.js";
import Component, { mixins } from "vue-class-component";
import Loading from "@/components/loading";
import { liveSessionService } from "@/services/liveSessionService.js";
import CountryFlag from "vue-country-flag";
import { localStorageService } from "@/services/localStorageService.js";
import { userService } from "@/services/userService.js";

@Component({
  components: {
    Loading,
    CountryFlag,
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    currentLanguage: function (_n, _o) {
      this.appointments.forEach((a) => {
        a.name = this.getAppointmentName(a);
        a.desc = this.getAppointmentDesc(a);
      });
    },
    speakerToImpersonate: async function (newVal) {
      if (newVal) {
        await this.loadData(newVal);
        this.$store.commit("SET_IMPERSONATED_USER", newVal);
        this.refreshCalendar(this.currentStart, this.currentEnd);
      } else {
        await this.loadData(this.loggedUserId);
        this.$store.commit("RESET_IMPERSONATED_USER");
        this.refreshCalendar(this.currentStart, this.currentEnd);
      }
    },
  },
})
export default class LiveSessionList extends mixins(Base) {
  currentStart = null;
  currentEnd = null;
  selectedEvent = {};
  speakerToImpersonate = null;
  selectedElement = null;
  selectedOpen = false;
  text_filter = "";
  isPageReady = false;
  liveSessions = [];
  type_filter = "ALL";
  status_filter = ["ALL", "ComingSoon", "Started", "Ended"];
  status_filters_cnt = 0;
  type = "month";
  types = ["month", "week", "day", "4day"];
  weekday = [0, 1, 2, 3, 4, 5, 6];
  weekdays = [
    { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
    { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
    { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
    { text: "Mon, Wed, Fri", value: [1, 3, 5] },
  ];
  value = "";
  appointments = [];
  colors = [
    "blue",
    "green",
    "orange",
    "deep-purple",
    "grey darken-1",
    "cyan",
    "pink",
    "indigo",
  ];
  legenda = [];
  isAvailableInVirtualPlace = false;
  switchDisable = false;

  StatusFilterCheck(isAllButton) {
    if (isAllButton) {
      if (this.status_filter.includes("ALL")) {
        this.status_filter = ["ALL", "ComingSoon", "Started", "Ended"];
      } else {
        if (this.status_filter.length == this.status_filters_cnt - 1)
          this.status_filter = [];
      }
    } else {
      if (
        !this.status_filter.includes("ALL") &&
        this.status_filter.length == this.status_filters_cnt - 1
      ) {
        this.status_filter = ["ALL", "ComingSoon", "Started", "Ended"];
      }
      if (
        this.status_filter.includes("ALL") &&
        this.status_filter.length != this.status_filters_cnt
      ) {
        this.status_filter = this.status_filter.filter((x) => x !== "ALL");
      }
    }
  }

  openLocalizedLiveSession(loc) {
    let langCode = this.language(loc.idLanguage)?.code;
    this.$router.push(`/live-session/${langCode}/${loc.idLiveSession}`);
  }

  getLocalizationCode(loc) {
    return this.language(loc.idLanguage)?.code;
  }

  getFormattedHTMLDescription(appointment) {
    return `${appointment.desc} <br><br>
    <strong> Start </strong> : ${appointment.start} <br>
    <strong> End </strong> : ${appointment.end} <br>
    <strong> Status </strong> : ${appointment.status} <br>
    <strong> Type </strong> : ${appointment.type} <br>
    <strong> Platform </strong> : ${appointment.platform} <br>`;
  }

  async swapAvailability(id, isAvailable) {
    this.switchDisable = true;
    if (!isAvailable) {
      await liveSessionService.setAvailable(id);
    } else if (isAvailable) {
      await liveSessionService.setNotAvailable(id);
    }

    this.isAvailableInVirtualPlace = !this.isAvailableInVirtualPlace;
    const targetIndex = this.liveSessions.findIndex((l) => l.id === id);
    if (targetIndex >= 0) {
      this.liveSessions[targetIndex].isAvailable =
        this.isAvailableInVirtualPlace;
    }
    this.switchDisable = false;
    this.refreshCalendar(this.currentStart, this.currentEnd);
  }

  viewDay({ date }) {
    this.value = date;
    this.type = "day";
  }

  showEvent({ nativeEvent, event }) {
    const open = () => {
      this.selectedEvent = event;
      this.isAvailableInVirtualPlace = this.selectedEvent.isAvailable;
      this.selectedElement = nativeEvent.target;
      requestAnimationFrame(() =>
        requestAnimationFrame(() => (this.selectedOpen = true))
      );
    };

    if (this.selectedOpen) {
      this.selectedOpen = false;
      requestAnimationFrame(() => requestAnimationFrame(() => open()));
    } else {
      open();
    }

    nativeEvent.stopPropagation();
  }

  resetFilter() {
    this.text_filter = "";
  }

  async loadData(idUser) {
    this.liveSessions = await liveSessionService.list(idUser);
  }

  async mounted() {
    await this.loadData(this.loggedUserId);
    this.$store.commit("RESET_IMPERSONATED_USER");
    this.speakers = await userService.speakers();
    this.status_filters_cnt = this.status_filter.length;
    this.isPageReady = true;
  }

  getAppointmentName(appointment) {
    return (
      this.getLocalizedField(
        appointment.localizations,
        this.currLang().code,
        appointment.idEvent,
        "title"
      ) ?? null
    );
  }

  getAppointmentDesc(appointment) {
    return (
      this.getLocalizedField(
        appointment.localizations,
        this.currLang().code,
        appointment.idEvent,
        "description"
      ) ?? null
    );
  }

  get isDailyCalendar() {
    return this.type != "month" && this.type != "week";
  }

  get adminView() {
    return localStorageService.getLoggedUser()?.groups?.includes("Admin");
  }

  get loggedUserId() {
    return localStorageService.getLoggedUser()?.id;
  }

  get appointments_filtered() {
    return this.appointments.filter(
      (item) =>
        (item.type === this.type_filter || this.type_filter === "ALL") &&
        (this.status_filter.includes(item.status) ||
          this.status_filter.includes("ALL")) &&
        item.name
          .toLowerCase()
          .includes(this.text_filter ? this.text_filter.toLowerCase() : "")
    );
  }

  refreshCalendar() {
    this.appointments = [];
    const distinctTypes = [];
    this.legenda = [];
    this.liveSessions.forEach((appointment) => {
      if (!distinctTypes.includes(appointment.type))
        distinctTypes.push(appointment.type);
      let name = this.getAppointmentName(appointment);
      this.appointments.push({
        ...appointment,
        name: appointment.parentType + " - " + name,
        start: new Date(appointment.startDate),
        end: new Date(appointment.endDate),
        color: "",
        timed: true,
        type: appointment.type,
        status: appointment.status,
        desc: this.getAppointmentDesc(appointment),
        platform: appointment.platform,
        localizations: appointment.localizations,
      });
    });
    const normalizedColors = this.colors.slice();
    for (let i = 0; i < distinctTypes.length; i++)
      this.appointments.forEach((d) => {
        if (d.type === distinctTypes[i]) {
          d.color = normalizedColors[i];
          if (!this.legenda.map((x) => x.type).includes(d.type))
            this.legenda.push({ color: normalizedColors[i], type: d.type });
        }
      });
  }

  getEvents({ start, end }) {
    this.currentStart = start;
    this.currentEnd = end;
    this.refreshCalendar(start, end);
  }

  getEventColor(event) {
    return event.color;
  }
}
</script>

<style lang="scss" scoped>
.livesessions-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: normal;
  background-color: #ebeef7;
  font-family: Arial;

  &__content {
    width: 100%;
    padding: 25px;
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.event_on_air {
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-animation: blink normal 1.5s infinite ease-in-out;
  /* Firefox */
  -webkit-animation: blink normal 1.5s infinite ease-in-out;
  /* Webkit */
  -ms-animation: blink normal 1.5s infinite ease-in-out;
  /* IE */
  animation: blink normal 1.5s infinite ease-in-out;
  /* Opera */
}
</style>
